<script setup>
import { create, registerPlugin } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { onMounted, ref } from "vue";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';

const uniqueId = ref(`image_uploader_${Math.floor(Math.random() * 10000)}`);

const props = defineProps({
    id: {
        type: String,
        default: "image_uploader_" + Math.floor(Math.random() * 1000) + 1,
        required: false
    },
    labelIdle: {
        default:
            "<span class='text-center text-neutral-500 text-base font-semibold leading-normal'>Drag & drop or <span class='text-primary-500'>upload an image</span></span>",
        type: String,
    },
    hint: {
        type: String,
        required: false,
    },
    error: {
        type: String,
        required: false,
    },
    confirmation: {
        type: String,
        required: false,
    },
    allowMultipleFiles: {
        type: Boolean,
        default: false,
    },
    allowReorder: {
        type: Boolean,
        default: false,
    },
    allowUpload: {
        type: Boolean,
        default: false,
    },
    uploadPath: {
        type: String,
        default: ''
    }
});

const pond = ref(null);
const emit = defineEmits(["update:modelValue"]);
registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview);

onMounted(() => {
    const s3Client = new S3Client({
        region: import.meta.env.VITE_AWS_DEFAULT_REGION,
        credentials: {
            accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
            secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
        },
    });

    const fileInput = document.querySelector("#" + props.id);

    pond.value = create(fileInput, {
        storeAsFile: true,
        dropOnPage: true,
        credits: null,
        maxFiles: 4,
        allowMultiple: props.allowMultipleFiles,
        labelIdle: props.labelIdle,
        allowReorder: props.allowReorder,
        server: {
            process: async function (fieldName, file, metadata, load, error, progress, abort) {
                let fileExtension = file.name.split(".").pop();
                const fileName = `${uuidv4()}.${fileExtension}`;
                const fileType = file.type.split("/")[0];

                // Handle video uploads to AWS
                if (fileType === 'video') {
                    const params = {
                        Bucket: import.meta.env.VITE_AWS_BUCKET,
                        Key: `videos/${fileName}`,
                        Body: file,
                        ContentType: file.type,
                    };

                    try {
                        const command = new PutObjectCommand(params);
                        const response = await s3Client.send(command);

                        if (response?.$metadata?.httpStatusCode === 200) {
                            emit("update:modelValue", {
                                'path': params.Key,
                                'type': fileType,
                                'isAws': true
                            });
                            progress(100);
                            load(file); // Mark upload as complete
                        } else {
                            error("Failed to upload video");
                            emit("update:modelValue", {
                                'filePath': params.Key,
                                'status': false,
                                'progress': 0
                            });
                        }
                    } catch (err) {
                        console.error("Error uploading video:", err.message || err);
                        emit("update:modelValue", { 'status': false, 'progress': 0 });
                    }
                    return; // Stop the process for video upload here
                }

                // Handle images (just return the file without uploading)
                if (fileType === 'image') {
                    // Emit image information without uploading
                    /*emit(
                        "update:modelValue",
                        [file]
                    );*/
                    load(file);
                    return;
                }
            },
            revert: null, // If you want to handle reverting, configure here
            restore: null, // Handle restoring files here if needed
            load: null, // Handle loading existing files if needed
        },
        onreorderfiles: (files, origin, target) => {
            emit(
                "update:modelValue",
                files.map((filepond) => filepond.file),
            );
        }
    });

    pond.value.on("updatefiles", (files) => {
        emit(
            "update:modelValue",
            files.map((filepond) => filepond.file),
        );
    });
});

const clearFiles = () => {
    if (pond.value) {
        pond.value.removeFiles();
    }
};

function onSorted(file) {
    console.log(file);
}


const fileInputRef = ref(null);
const triggerManually = () => {
    pond.value.browse();
}

defineExpose({
    clearFiles,
    triggerManually
});
</script>

<template>
    <div>
        <div class="">
            <input :id="id" type="file" ref="fileInputRef"/>
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-danger-500">
            {{ props.error }}
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-primary-500">
            {{ props.hint }}
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-success-600">
            {{ props.confirmation }}
        </div>
    </div>
</template>

<style scoped></style>
