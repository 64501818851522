<script setup>
import { post } from "jquery";
import Modal from "@/Components/Modal.vue";
import FileUploader from "@/Components/AwsFileUploader.vue";
import GenericButton from "@/Components/GenericButton.vue";
import {computed, onMounted, onUpdated, ref, watch} from "vue";
import Avatar from "@/Components/Avatar.vue";
import axios from "axios";
import { useForm } from "@inertiajs/vue3";

const form = ref({
    text: "",
    files: [],
    filePath: "",
    fileType: "",
});
const props = defineProps({
    modalOpen: Boolean,
    trigger: String,
});
const linkPreviews = ref([]);
const emit = defineEmits(["closeModal", "update:data"]);
const fileUploaderValue = (files) => {
    if (files?.isAws) {
        console.log(files.type, files.path, "file type");
        form.value.filePath = files.path;
        form.value.fileType = files.type;
    } else {
        form.value.files = files;
        console.log(files, "else file type");
    }
};

const loadCandidateCompletion = async () => {
    await axios
        .get(route("candidate.completion"))
        .then((response) => {
            candidateCompletion.value = response.data.percentage;
        })
        .catch((err) => {
            console.log("Error:", err);
        });
};
const handlePost = async () => {
    let formData = new FormData();
    formData.append("text", form.value.text);
    if (!form.value.filePath) {
        form.value.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });
        // formData.append('files', form.value.files);
    } else {
        formData.append("filePath", form.value.filePath);
        formData.append("fileType", form.value.fileType);
    }

    try {
        const response = await axios.post(route("news_feed"), formData);
        emit("update:data", response.data.posts);
        emit("closeModal");
        form.value.text = "";
        form.value.files = [];
        linkPreviews.value = [];
        clearFilesHandler();
    } catch (error) {
        console.error("Error:", error);
    }
};
const fileUploaderRef = ref(null);
const fileUploaderRefSpecial = ref(null);
const clearFilesHandler = () => {
    if (fileUploaderRefSpecial.value) {
        fileUploaderRefSpecial.value.clearFiles();
    }
    if (fileUploaderRef.value) {
        fileUploaderRef.value.clearFiles();
    }
};
onMounted(() => {
    watch(() => props.trigger, (newVal) => {
            setTimeout(() => {
                if (newVal && fileUploaderRef.value) {
                    if (newVal === "photo") {
                        fileUploaderRef.value.triggerManually();
                    } else if (newVal === "video") {
                        fileUploaderRef.value.triggerManually();
                    }
                }
            }, 500); //delay to allow the modal to open
        }
    );
});
const handlePaste = () => {
    setTimeout(() => {
        updateLinkPreviews();
    }, 100);
};
const updateLinkPreviews = () => {
    const text = form.value.text;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = text.match(urlRegex) || [];
    const currentUrlsSet = new Set(urls);

    // Remove links from linkPreviews
    linkPreviews.value = linkPreviews.value.filter((preview) =>
        currentUrlsSet.has(preview.original)
    );

    // Check if there are new URLs to process
    if (urls.length === 0) {
        return;
    }

    // Set for new URLs that need previews
    const newUrlsSet = new Set(urls);

    // Remove URLs that already have previews
    linkPreviews.value.forEach((preview) =>
        newUrlsSet.delete(preview.original)
    );

    if (newUrlsSet.size > 0) {
        loading.value = true;
        const newUrls = Array.from(newUrlsSet);

        axios
            .put(route("feed.load_url_previews"), { urls: newUrls })
            .then((response) => {
                const handledUrls = response.data.urls || [];
                handledUrls.forEach((url) => {
                    if (
                        !linkPreviews.value.find(
                            (preview) => preview.original === url.original
                        )
                    ) {
                        linkPreviews.value.push(url);
                    }
                });
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                loading.value = false;
            });
    }
};

const uploadPhotoButtonLabel1 = `
<div class="flex items-center justify-center gap-2 text-sm font-bold cursor-pointer text-primary"
                            >
                                <div class="">
											<svg
        v-if="type == 'photo'"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.3359 10.698C20.3339 12.168 20.3229 13.416 20.2399 14.441C20.1429 15.631 19.9439 16.625 19.4999 17.45C19.3062 17.8117 19.0602 18.143 18.7699 18.433C17.9369 19.266 16.8769 19.643 15.5329 19.823C14.2199 20 12.5359 20 10.3889 20H10.2829C8.13494 20 6.45294 20 5.13894 19.823C3.79594 19.643 2.73494 19.266 1.90294 18.433C1.16494 17.695 0.782937 16.777 0.580937 15.638C0.380937 14.518 0.344938 13.126 0.337938 11.397C0.335938 10.957 0.335938 10.492 0.335938 10V9.94602C0.335938 7.79802 0.335938 6.11602 0.512938 4.80202C0.692938 3.45902 1.06994 2.39802 1.90294 1.56602C2.73494 0.733023 3.79594 0.356024 5.13894 0.176024C6.30694 0.0190236 7.80894 0.00102344 9.63794 -0.000976562C9.82306 -0.000976562 10.0006 0.0725626 10.1315 0.203463C10.2624 0.334363 10.3359 0.511902 10.3359 0.697024C10.3359 0.882145 10.2624 1.05968 10.1315 1.19058C10.0006 1.32148 9.82306 1.39502 9.63794 1.39502C7.78294 1.39702 6.40394 1.41302 5.32494 1.55802C4.13594 1.71802 3.41894 2.02202 2.88894 2.55202C2.35894 3.08202 2.05594 3.80002 1.89594 4.99002C1.73294 6.20002 1.73094 7.78802 1.73094 10V10.784L2.66294 9.97002C3.07147 9.61259 3.60056 9.42375 4.14308 9.44176C4.68561 9.45977 5.20101 9.68328 5.58494 10.067L9.57494 14.057C9.8845 14.3668 10.2934 14.5574 10.7297 14.5952C11.166 14.6331 11.6016 14.5158 11.9599 14.264L12.2379 14.069C12.7547 13.7058 13.3795 13.5287 14.01 13.5666C14.6405 13.6046 15.2395 13.8554 15.7089 14.278L18.3419 16.648C18.6069 16.091 18.7649 15.36 18.8489 14.328C18.9279 13.356 18.9389 12.176 18.9399 10.698C18.9399 10.5129 19.0135 10.3354 19.1444 10.2045C19.2753 10.0736 19.4528 10 19.6379 10C19.8231 10 20.0006 10.0736 20.1315 10.2045C20.2624 10.3354 20.3359 10.5129 20.3359 10.698Z"
            fill="#212B36"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8359 9.00002C13.7149 9.00002 12.6539 9.00002 11.9949 8.34102C11.3359 7.68202 11.3359 6.62102 11.3359 4.50002C11.3359 2.37902 11.3359 1.31802 11.9949 0.659023C12.6539 2.34246e-05 13.7149 2.34842e-05 15.8359 2.34842e-05C17.9569 2.34842e-05 19.0179 2.34246e-05 19.6769 0.659023C20.3359 1.31802 20.3359 2.37902 20.3359 4.50002C20.3359 6.62102 20.3359 7.68202 19.6769 8.34102C19.0179 9.00002 17.9569 9.00002 15.8359 9.00002ZM16.5859 2.50002C16.5859 2.30111 16.5069 2.11035 16.3663 1.96969C16.2256 1.82904 16.0349 1.75002 15.8359 1.75002C15.637 1.75002 15.4463 1.82904 15.3056 1.96969C15.165 2.11035 15.0859 2.30111 15.0859 2.50002V3.75002H13.8359C13.637 3.75002 13.4463 3.82904 13.3056 3.96969C13.165 4.11035 13.0859 4.30111 13.0859 4.50002C13.0859 4.69894 13.165 4.8897 13.3056 5.03035C13.4463 5.17101 13.637 5.25002 13.8359 5.25002H15.0859V6.50002C15.0859 6.69894 15.165 6.8897 15.3056 7.03035C15.4463 7.17101 15.637 7.25002 15.8359 7.25002C16.0349 7.25002 16.2256 7.17101 16.3663 7.03035C16.5069 6.8897 16.5859 6.69894 16.5859 6.50002V5.25002H17.8359C18.0348 5.25002 18.2256 5.17101 18.3663 5.03035C18.5069 4.8897 18.5859 4.69894 18.5859 4.50002C18.5859 4.30111 18.5069 4.11035 18.3663 3.96969C18.2256 3.82904 18.0348 3.75002 17.8359 3.75002H16.5859V2.50002Z"
            fill="#212B36"
        />
    </svg>
                                </div>
                                <span>Add Photo</span>
                            </div>`;
const uploadVideoButtonLabel1 = `
<div class="flex items-center justify-center gap-2 text-sm font-bold cursor-pointer text-primary"
                            >
                                <div class="">
											<svg
        v-if="type == 'video'"
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 8.5V7.5C0 4.213 0 2.569 0.908 1.462C1.07409 1.25965 1.25965 1.07409 1.462 0.908C2.57 0 4.212 0 7.5 0C10.787 0 12.431 0 13.538 0.908C13.7404 1.07409 13.9259 1.25965 14.092 1.462C14.794 2.317 14.953 3.493 14.989 5.5L15.659 5.17C17.604 4.198 18.577 3.711 19.289 4.151C20 4.591 20 5.679 20 7.854V8.146C20 10.321 20 11.409 19.289 11.849C18.577 12.289 17.604 11.802 15.659 10.829L14.989 10.5C14.953 12.507 14.794 13.683 14.092 14.538C13.9259 14.7403 13.7403 14.9259 13.538 15.092C12.43 16 10.788 16 7.5 16C4.213 16 2.569 16 1.462 15.092C1.25965 14.9259 1.07409 14.7404 0.908 14.538C0 13.43 0 11.788 0 8.5ZM11.56 5.56C11.7056 5.42219 11.8221 5.25659 11.9027 5.07298C11.9832 4.88937 12.0261 4.69148 12.0288 4.491C12.0316 4.29053 11.9941 4.09154 11.9186 3.9058C11.8432 3.72005 11.7312 3.55133 11.5894 3.40959C11.4476 3.26785 11.2788 3.15598 11.093 3.08059C10.9073 3.0052 10.7083 2.96781 10.5078 2.97065C10.3073 2.97348 10.1094 3.01648 9.92586 3.0971C9.74229 3.17771 9.57674 3.29431 9.439 3.44C9.16589 3.72303 9.01488 4.10201 9.01848 4.4953C9.02208 4.8886 9.18001 5.26474 9.45826 5.54273C9.7365 5.82071 10.1128 5.97828 10.5061 5.98151C10.8994 5.98475 11.2772 5.83337 11.56 5.56Z"
            fill="#212B36"
        />
    </svg>
                                </div>
                                <span>Add Video</span>
                            </div>`;
</script>

<template>
    <modal @close="$emit('closeModal')" :show="modalOpen">
        <div class="relative overflow-hidden">
            <div class="flex mb-4">
                <h6
                    class="flex-auto text-lg font-semibold text-center text-primary"
                >
                    {{ $t("Create a post") }}
                </h6>
                <div
                    @click="$emit('closeModal')"
                    class="flex items-center justify-center cursor-pointer shrink-0"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.99829 10.056L13.239 14.297C13.3804 14.4335 13.5697 14.5091 13.7663 14.5074C13.9628 14.5057 14.1509 14.4268 14.2899 14.2878C14.4288 14.1488 14.5077 13.9608 14.5094 13.7642C14.5111 13.5677 14.4355 13.3783 14.299 13.2369L10.0583 8.99589L14.299 4.75489C14.4355 4.61349 14.5111 4.42412 14.5094 4.22755C14.5077 4.03099 14.4288 3.84295 14.2899 3.70395C14.1509 3.56496 13.9628 3.48611 13.7663 3.4844C13.5697 3.4827 13.3804 3.55826 13.239 3.69482L8.99829 7.93582L4.75759 3.69482C4.61557 3.56164 4.42731 3.48893 4.23264 3.49209C4.03798 3.49525 3.85217 3.57403 3.71455 3.71176C3.57693 3.84949 3.49829 4.03536 3.49527 4.23004C3.49224 4.42473 3.56508 4.61295 3.69835 4.75489L7.93831 8.99589L3.69761 13.2369C3.62601 13.306 3.5689 13.3888 3.52961 13.4802C3.49032 13.5717 3.46964 13.6701 3.46878 13.7696C3.46791 13.8692 3.48688 13.9679 3.52457 14.06C3.56227 14.1522 3.61793 14.2359 3.68831 14.3062C3.7587 14.3766 3.8424 14.4323 3.93453 14.47C4.02665 14.5077 4.12536 14.5267 4.2249 14.5258C4.32444 14.5249 4.42281 14.5043 4.51426 14.465C4.60572 14.4257 4.68844 14.3686 4.75759 14.297L8.99829 10.056Z"
                            fill="#637381"
                        />
                    </svg>
                </div>
            </div>
            <div class="flex gap-2 mb-6">
                <div class="">
                    <Avatar
                        :user="$page.props.auth.user"
                        :postOrganization="currentorganization"
                        size="10"
                    />
                </div>
                <div class="text-base font-semibold text-primary">
                    {{
                        $page.props.auth.organization?.name ||
                        `${$page.props.auth.user.firstname} ${$page.props.auth.user.lastname}`
                    }}
                </div>
            </div>
            <form action="" @submit.prevent="handlePost">
                <div class="flex-auto">
                    <div class="border-b border-divider">
                        <textarea
                            v-model="form.text"
                            class="block w-full !border-b p-0 border-divider h-[200px] text-2xl focus:ring-transparent resize-none border-none placeholder:text-disabled text-primary"
                            :placeholder="
                                $t(
                                    `What’s on your mind ${$page.props.auth.user.firstname}?`
                                )
                            "
                        ></textarea>
                    </div>
                    <div
                        class="flex mb-3"
                        :class="[
                            form.files.length > 0 ? 'flex-col' : 'flex-row',
                        ]"
                    >
                        <div class="z-10 block w-full py-1.5">
                            <FileUploader
                                class="feed"
                                ref="fileUploaderRef"
                                :class="[form.files.length > 0]"
                                :labelIdle="uploadPhotoButtonLabel1"
                                :allowMultipleFiles="true"
                                :allowReorder="true"
                                :allowUpload="true"
                                @update:modelValue="fileUploaderValue"
                                id="adasjkdzxhcaoqoiwui00000001234561"
                                :upload-path="route('upload.files')"
                            />
                        </div>
                        <div class="z-10 block w-full py-1.5">
                            <FileUploader
                                class="feed"
                                ref="fileUploaderRefSpecial"
                                :class="[form.files.length > 0]"
                                :labelIdle="uploadVideoButtonLabel1"
                                :allowMultipleFiles="true"
                                :allowReorder="true"
                                :allowUpload="true"
                                @update:modelValue="fileUploaderValue"
                                id="adasjkdzxhcaoqoiwui00000001234571"
                                :upload-path="route('upload.files')"
                            />
                            <InputError
                                class="mt-2"
                                :message="form.errors?.files"
                            />
                        </div>
                    </div>
                    <div class="">
                        <GenericButton
                            type="button"
                            theme="main-contained"
                            :text="$t('Post')"
                            class="w-full"
                            @click="handlePost"
                            @submit="handlePast"
                        ></GenericButton>
                    </div>
                </div>
            </form>
        </div>
    </modal>
</template>

<style>
.feed .filepond--root {
    font-size: 14px;
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
}
.feed .filepond--panel-root {
    background-color: inherit;
    cursor: pointer;
}

.feed .filepond--root .filepond--drop-label {
    min-height: 2.25em !important;
    border-radius: 9999px !important;
}
</style>
